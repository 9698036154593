import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/Seo/Seo"
import PageHeader from "../components/PageHeader/PageHeader"
import Footer from "../components/Footer/Footer"
import Container from "../components/Container/Container"
import Box from "../components/Box/Box"
import PropertiesTable from "../components/PropertiesTable/PropertiesTable"
import BrowsersTable from "../components/BrowsersTable/BrowsersTable"
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"
import RelatedProperties from "../components/RelatedProperties/RelatedProperties"

const CssProperties = ({ data, location }) => {
  const item = data.allSitePage.edges[0].node.pageContext
  const breadcrumbsItems = [
    { name: "Właściwości CSS", href: "/wlasciwosci-css/" },
    { name: item.property },
  ]
  return (
    <>
      <Seo
        title={`CSS ${item.property} | CSSGENERATOR.PL`}
        description={`CSS ${item.property} | Wygodne narzędzie, dzięki któremu wygenerujesz gotowe reguły CSS. Dostosuj wartości i skopiuj gotowy kod.`}
        location={location}
        breadcrumbs={breadcrumbsItems}
      />
      <PageHeader
        h1={`CSS ${item.property}`}
        desc={`Poniżej znajdziesz informacje dotyczące właściwości CSS ${item.property}.`}
        notGenerator
      />

      <Container>
        <div className="properties__wrapper">
          <Box>
            <Breadcrumbs items={breadcrumbsItems} />
          </Box>
          <Box>
            <h2>Informacje o {item.property}</h2>
            <p>{item.content}</p>
            <PropertiesTable data={item} />
          </Box>
          <Box>
            <h2>{item.property} - zgodność z przeglądarką</h2>

            <BrowsersTable data={item.browsers} />
          </Box>
          <Box>
            <h2>{item.property} - CSS syntax</h2>
            <pre>{item.syntax}</pre>
          </Box>
          <Box>
            <h2>Zobacz inne właściwości CSS</h2>
            <RelatedProperties data={item.relatedProperties} />
          </Box>
          <i>źródło: </i>{" "}
          <a href={item.url} target="_blank" rel="nofollow noopener noreferrer">
            <i>{item.url}</i>
          </a>
        </div>
      </Container>

      <Footer />
    </>
  )
}

export default CssProperties

export const query = graphql`
  query ($path: String!) {
    allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          pageContext
        }
      }
    }
  }
`
