import React from "react"

import "./PropertiesTable.scss"

const PropertiesTable = ({ data }) => (
  <table className="property__table">
    <tbody>
      {data.info.map((item, index) => (
        <tr key={index}>
          <th>{item.name}</th>
          <td>{item.value}</td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default PropertiesTable
