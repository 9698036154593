import React from "react"
import { Link } from "gatsby"
import "./RelatedProperties.scss"

const RelatedProperties = ({ data }) => (
  <ul className="relatedProperties">
    {data.map(item => (
      <li key={item}>
        <Link to={`/wlasciwosci-css/${item}/`}>{item}</Link>
      </li>
    ))}
  </ul>
)

export default RelatedProperties
