import React from "react"

import "./BrowsersTable.scss"

const BrowsersTable = ({ data }) => (
  <table className="browsers__table">
    <tbody>
      <tr>
        <th>Właściwość CSS</th>
        <th>Chrome</th>
        <th>Edge</th>
        <th>Firefox</th>
        <th>Safari</th>
        <th>Opera</th>
      </tr>
      {data.map((item, index) => (
        <tr key={index}>
          <td dangerouslySetInnerHTML={{ __html: item.name }}></td>
          <td dangerouslySetInnerHTML={{ __html: item.chrome }}></td>
          <td dangerouslySetInnerHTML={{ __html: item.edge }}></td>
          <td dangerouslySetInnerHTML={{ __html: item.firefox }}></td>
          <td dangerouslySetInnerHTML={{ __html: item.safari }}></td>
          <td dangerouslySetInnerHTML={{ __html: item.opera }}></td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default BrowsersTable
